<script lang="ts" setup>
withDefaults(
  defineProps<{
    isActive?: boolean
    isDisabled?: boolean
    time: string | null
  }>(),
  { isActive: false, isDisabled: false }
)

const emit = defineEmits<{
  (e: 'selected'): void
}>()
</script>

<template>
  <a
    class="
      font-regular swiper-slide mr-2 !w-min rounded-xl border border-solid
      border-transparent bg-white p-1.5
      text-sm font-medium text-main-dark
      !no-underline
      sm:p-2
      md:px-3 md:py-2.5
    "
    :class="{ '!border-main-green': isActive, 'pointer-events-none cursor-default !text-[#d1d1d1]': isDisabled }"
    href="#"
    @click.prevent="emit('selected')"
  >
    {{ time }}
  </a>
</template>
